import React from "react";
import { graphql } from "gatsby";
import { Layout } from "./Layout";
import "./BlogPostLayout.css";

export default function BlogPostLayout({ data }) {
  const {
    markdownRemark: post,
    site: {
      siteMetadata: { title: siteTitle },
    },
  } = data;
  return (
    <Layout pageTitle={`${siteTitle} - ${post.frontmatter.title}`}>
      <div className="prose mx-auto">
        <h1 className="blogpost-title">{post.frontmatter.title}</h1>
        <p className="text-copy-lightgray blogpost-date">{post.frontmatter.date}</p>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`;
